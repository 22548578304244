import * as React from "react";

export const PrimaryButton = ({ children }) => {
  return (
    <a href="#" className="btn btn-primary">
      {children}
    </a>
  );
};

export const SecondaryButton = ({ children }) => {
  return (
    <a href="#" className="btn btn-secondary">
      {children}
    </a>
  );
};
